// Custom scss

// Mixin

@mixin text3d ($color: #a6a6a6, $x: 1.25, $y: -1,  $darken: 1) {
  color: $color;
  text-shadow: 0px 0px 0 darken($color, 14%),
  $x* 1px $y* 1px 0 darken($color, $darken * 15),
  $x* 2px $y* 2px 0 darken($color, $darken * 16),
  $x* 3px $y* 3px 0 darken($color, $darken * 17),
  $x* 4px $y* 4px 0 darken($color, $darken * 18),
  $x* 5px $y* 5px 0 darken($color, $darken * 19),
  $x* 6px $y* 6px 0 darken($color, $darken * 20),
  $x* 7px $y* 7px 0 darken($color, $darken * 21),
  $x* 8px $y* 8px 0 darken($color, $darken * 22),
  $x* 9px $y* 9px 0 darken($color, $darken * 23),
  $x* 10px $y* 10px 0 darken($color, $darken * 24),
  $x* 11px $y* 11px 0 darken($color, $darken * 25), 
  $x* 12px $y* 12px 0 darken($color, $darken * 26) !important;
  // $x* 13px $y* 13px 12px rgba(0,0,0,0.55), 
  // $x* 13px $y* 13px 1px rgba(0,0,0,0.5);
}

@mixin hackerman-bg-maker ($offset: 60px, $start-color: #7471d9, $end-color: #80f2c9) {
  @for $i from 1 through 10 {
    &:nth-child(#{11 - $i}) {
      animation: move_wave_#{$i} 5s alternate ease-in-out #{$i * 200}ms infinite;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate3d($i * $offset, $i * $offset, 0);
      // background-color: transparentize(mix($start-color, $end-color, $weight: $i * 10%), $i * 0.1%);
      background-color: scale-color(mix($start-color, $end-color, $weight: $i * 10%), $alpha: $i * -9%);
    }

    @keyframes move_wave_#{$i} {
      0% {
        transform: translate3d($i * 60px, $i * 60px, 0);
      }
      50% {
        transform: translate3d($i * 60px + 2 * $i, $i * 60px - 2 * $i, 0);
      }
      100% {
        transform: translate3d($i * 60px - 2 * $i, $i * 60px + 2 * $i, 0);
      }
    }
  }
}
// Mixin

@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

// Diagonal separator

$diagonal-offset: 80px;
$svg-width: 540px;
$svg-width-down-sm: 400px;

.bg-jumbo-gradient {
  background: linear-gradient($black, $blue);
}

.hackerman-bg {
  @include hackerman-bg-maker();
  
  &-wrapper {
    position: relative;
    max-width: $svg-width;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      max-width: $svg-width-down-sm;
    }
  }
}

.nft-info {
  height: 39px;
  border-radius: 0;
  margin: 0 auto;
  background-color: rgba(var(--bs-dark-rgb), .5);
  backdrop-filter: blur(20px);
  font-size: 0.875em;
  @include media-breakpoint-down(sm) {
    font-size: 0.775em;
  }
}

.nft-image {
  position: relative;
  user-select: none;
}

.nft-container-jumbo {
  // FIXME: 100vh - navbar computed height - diagonal separator
  // height: calc(100vh - $diagonal-offset);
  height: 100vh;

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 120px);
  }

  svg {
    width: $svg-width;
    height: 100%;
    opacity: 0;
    transition: linear .2s opacity;
    font-weight:400;font-style:normal;
    // background-image: linear-gradient(117deg, #7471d9 0%, #80f2c9 100%);
    // .C {
    //   @include text3d();
    // }

    @include media-breakpoint-down(sm) {
      width: $svg-width-down-sm;
    }
  }
}

.nft-container {
  svg {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: linear .2s opacity;
    // background-image: linear-gradient(117deg, #7471d9 0%, #80f2c9 100%);
    // .C {
    //   @include text3d();
    // }
  }
}

.nft-image-loaded {
  svg {
    opacity: 1;
  }
}

.nft-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.diagonal-separator {
  
  &-up,
  &-down {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: $diagonal-offset;
      background-color: inherit;
      transform: translate3d(0, -$diagonal-offset + 2px, 0);
    }
    
    &.py-6 {
      padding-bottom: calc(6rem + $diagonal-offset) !important;

      &:last-child {
        padding-bottom: 6rem !important;
      }
    }
    
    &.py-4 {
      padding-bottom: calc(1.5rem + $diagonal-offset) !important;

      &:last-child {
        padding-bottom: 1.5rem !important;
      }
    }
    
    &.py-3 {
      padding-bottom: calc(1rem + $diagonal-offset) !important;

      &:last-child {
        padding-bottom: 1rem !important;
      }
    }
  }
  
  &-up::before {
    // clip-path: polygon(100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 calc(100% - 2px), 100% 0, 100% 100%, 0 100%);
  }
  
  &-down::before {
    // clip-path: polygon(100% 100%, 0 0, 0 100%);
    clip-path: polygon(0 0, 100% calc(100% - 2px), 100% 100%, 0 100%);
  }
}

#stc {
  max-width: $svg-width;
}

.accordion-item:first-of-type {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  
  .accordion-header,
  .accordion-button {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;

    &:not(.collapsed) { 
      border-radius: 25px 25px 0 0 !important;
      // color: inherit;
    }
  }
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  
  .accordion-header,
  .accordion-button {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    
    &:not(.collapsed) { 
      border-radius: 0 !important;
      // color: inherit;
    }
  }
}

.accordion-item {
  border: 1px solid #ebedf4;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -500px 0
  }
  100%{
    background-position: 500px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  // background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 100%;
  position: relative;
}

.invalid-feedback {
  display: block;
  min-height: 1.5rem;
}

.interact-modal {
  .modal-header {
    border-bottom-color: $dark;
  }
  .modal-footer {
    border-top-color: $dark;
  }
  .modal-body {
    min-height: 80px;
  }
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.pop-price {
  .tooltip-arrow::before {
    border-top-color: $dark;
  }
  .tooltip-inner {
    background-color: $dark;
  }
}

.form-label {
  &.h6 {
    line-height: 1.5;
  }
}


.disable-md-br {
  @include media-breakpoint-down(md) {
    br {
      display: none;
    }
  }
}

.btn-icon {
  font-size: 1.3em;
}

// breakpoint debug

  // body::before {
  //   position: fixed;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 1;
  //   padding: 0.25rem 0.5rem;
  //   background: rgba(0,0,0,0.75);
  //   color: #fff;
  //   border-radius: 0 0 0 0.25rem;
  // }
  
  // @media (min-width: 0px) { 
  //   body::before { content: "XS"; }
  // }
  
  // @media (min-width: 576px) { 
  //   body::before { content: "SM"; }
  // }
  
  // @media (min-width: 768px) { 
  //   body::before { content: "MD"; }
  // }
  
  // @media (min-width: 992px) { 
  //   body::before { content: "LG"; }
  // }
  
  // @media (min-width: 1200px) { 
  //   body::before { content: "XL"; }
  // }
  
  // @media (min-width: 1400px) { 
  //   body::before { content: "XXL"; }
  // }